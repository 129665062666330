import logo_symbol from './logo_symbol.svg';
import logo_brand from './logo_brand.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
      <div className="logo">
        <div className="wrap">
          <div  className="symbol">
            <img src={logo_symbol} alt="logo-symbol" />
          </div>
          <div  className="brand">
            <img src={logo_brand} alt="logo-brand" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
